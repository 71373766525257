import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "./Header";
import Sidebar from "./Sidebar";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex"
  }
}));

export default function Nav() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const navItems = [
    // { label: "Home", url: "/" },
    // { label: "About", url: "about" },
    // { label: "Contact", url: "contact" }
  ];
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <Header toggleDrawer={toggleDrawer}  navItems={navItems}/> */}
      {/* <Sidebar open={open} toggleDrawer={toggleDrawer} navItems={navItems} /> */}
    </div>
  );
}
