import React, { useRef } from "react";
import {
  makeStyles,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Container,
  Grid,
  Paper
} from "@material-ui/core";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import FeatureItem from "../components/FeatureItem";
import { Whatsapp, Email, Phone } from "mdi-material-ui";

const useStyles = makeStyles(theme => ({
  hero: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    height: "450px",
    [theme.breakpoints.up("md")]: {
      height: "600px"
    },
    backgroundImage: `url(${require("../assets/slider_2.jpg")})`,
    backgroundSize: "cover",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.dark
  },
  heroTextMain: {
    color: theme.palette.common.white,
    textAlign: "center",
    fontWeight: "600"
  },
  getStartedBtn: {
    marginTop: theme.spacing(5)
  },
  section: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10)
    }
  },
  sectionHeading: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(10)
    },
    textAlign: "center"
  },
  contactPaper: {
    padding: theme.spacing(3),
    textAlign: "center"
  },
  contactIcon: {
    fontSize: 30
  },
  spacer: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(20)
    }
  }
}));

function Home() {
  const featureRef = useRef(null);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const featureItems = [
    { icon: "smartphone", label: "Mobile App Development" },
    { icon: "language", label: "Website Design & Hosting" },
    { icon: "web", label: "Web App Development" },
    { icon: "computer", label: "Desktop App Development" }
  ];

  const callNumber = () => {
    isMobile && window.open("tel:233249937555");
  };

  const openWhatsApp = () => {
    isMobile && window.open("whatsapp://send?phone=233555120939");
  };

  const sendEmail = () => {
    isMobile && window.open("mailto:hello@jemmytech.com");
  };

  return (
    <div>
      <div className={classes.hero}>
        <Typography
          variant={isMobile ? "h4" : "h2"}
          className={classes.heroTextMain}
        >
          Bringing Technology To Your Doorstep
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          className={classes.getStartedBtn}
          startIcon={<ArrowDownwardRoundedIcon />}
          onClick={() =>
            window.scrollBy({
              left: 0,
              top: featureRef.current.offsetTop,
              behavior: "smooth"
            })
          }
        >
          Get Started
        </Button>
      </div>
      <Container maxWidth="md" ref={featureRef} className={classes.section}>
        <Typography className={classes.sectionHeading} variant="h4">
          Get these and many more ...
        </Typography>
        <Grid container spacing={isMobile ? 5 : 10}>
          {featureItems.map(item => (
            <Grid item xs={12} sm={6} key={item.label}>
              <FeatureItem label={item.label} icon={item.icon} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <div className={classes.spacer}></div>
      <Container maxWidth="sm" className={classes.section}>
        <Typography className={classes.sectionHeading} variant="h4">
          Link up to Get Started
        </Typography>
        <Grid container spacing={isMobile ? 3 : 5}>
          <Grid item xs={12} sm={6} onClick={callNumber}>
            <Paper className={classes.contactPaper}>
              <Phone className={classes.contactIcon} color="secondary" />
              <Typography>0555120939</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.contactPaper} onClick={openWhatsApp}>
              <Whatsapp className={classes.contactIcon} color="secondary" />
              <Typography>0555120939</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.contactPaper} onClick={sendEmail}>
              <Email className={classes.contactIcon} color="secondary" />
              <Typography>hello@jemmytech.com</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Home;
