import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
  },
  media: {
    height: 140,
  },
  icon: {
    fontSize: 60,
    textAlign: 'center',
    width: '100%',
    marginTop: theme.spacing(4),
    color: theme.palette.secondary.main
  },
  label: {
    textAlign: 'center'
  }
}));



function FeatureItem({label, icon, description}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        {/* <CardMedia
          className={classes.media}
          image="/static/images/cards/contemplative-reptile.jpg"
          title="Contemplative Reptile"
        /> */}
        <Icon className={classes.icon}>{icon}</Icon>
        <CardContent>
          <Typography className={classes.label} gutterBottom variant="h5" component="h2">
            {label}
          </Typography>
          <Typography className={classes.description} variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

FeatureItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default FeatureItem;