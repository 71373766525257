import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  list: {
    width: 230
  },
  drawer: {},
  listInner: {
    marginTop: 70
  }
}));

export default function Sidebar({ open, toggleDrawer, navItems }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <SwipeableDrawer
      className={classes.drawer}
      anchor={"left"}
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      <div
        className={clsx(classes.list)}
        role="presentation"
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      >
        <List className={classes.listInner}>
          {navItems.map(({ label, url }) => (
            <ListItem button key={label}>
              {/* <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon> */}
              <ListItemText primary={label} onClick={() => history.push(url)} />
            </ListItem>
          ))}
        </List>
      </div>
    </SwipeableDrawer>
  );
}
